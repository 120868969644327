$(document).ready(function() {

     /*phone code ------------------------------------------------ */
     $.ajax({
        url: countryjsonurl,
        type:'get',
        dataType: 'json',
        success: function(response)
        {
            var options = "";
            $.each(response, function(index, value){
                options += "<option data-index="+index+' value="'+value.code+'" data-phone-code="'+value.dial_code+'" data-country-id="'+index+'" data-country-name="'+value.name+'">'+value.code+'&emsp;&emsp; - &emsp;&emsp;'+value.name+'</option>';
            });
            $('select.country-code').html(options).trigger('change');
        }
    });
    
    $("select.country-code").on('change', function(e){
        
        var phonecode = $(this).find(':selected').data('phone-code');
        var countrycode = $(this).find(':selected').val();
    
        $(this).closest('.phonecode-field').find('span.prefix').text(phonecode);
        $(this).closest('.phonecode-field').find('input.telephone').val('');
        $(this).closest('.phonecode-field').find('input.phonenumber').val(phonecode);
        $(this).closest('.phonecode-field').find('input.phonecode').val(phonecode);
        $(this).closest('.phonecode-field').find('input.countrycode').val(countrycode);
    });
    $('input.telephone').on('focusout', function(event){
        var prefix = $(this).closest('.phonecode-field').find('span.prefix').text();
        var data = $(this).val();
        if(data.startsWith('0'))
        {
            data = data.substring(1,data.length);
        }
        var number = prefix + data;
        $(this).closest('.phonecode-field').find('input.phonenumber').val(number);
    });
     /* end phone code ------------------------------------------------ */


     /* Show More - Show Less ------------------------------------------------ */
     $(".text-toggle").each(function() {
        var textDivHeight = $(this).height();
     
        if(textDivHeight > 440){
        
            $(this).next(".moreless").css("display","flex");
        } else{
            $(this).addClass('toggle');
            $(this).next(".moreless").css("display","none");
        }
    });
    $('.action-toggle').click(function(event) {
        event.preventDefault();
        var id = $(this).attr('href');
        $(id).toggleClass('toggle');
        
        if($(id).hasClass("toggle")) {  
		
            $(this).html("Show less");
			//window.location.replace="id";

			// $(this).animate({
				//scrollTop: $(id).offset().top
			//  }, 2000);

		
        } else {
            $(this).html("Show more");
        } 
    });

  /* Smooth Scroll ------------------------------------------------------ */
  $("#goToTop").on('click', function(event) 
  {
      event.preventDefault();
      var hash = this.hash;    
      $('html, body').animate({ scrollTop: $(hash).offset().top }, 800, 
          function() { window.location.hash = hash; });
  });

  /* scroll ------------------------------------------------------ */
  $(window).scroll(function(){
  
      scroll = $(window).scrollTop();

       if(scroll <= 400)$("a#goToTop").addClass("godown");
      else $("a#goToTop").removeClass("godown");
 
  });
  $(".anchor_").on('click', function() {
        var go_to_url = $(this).attr("href");
        window.location.href = go_to_url;
  });
    $('.smoothscroll').on('click', function(e) {
        e.preventDefault();
            if (this.hash !== "") {
              event.preventDefault();
              var hash = this.hash;
              $('html, body').animate({
                scrollTop: $(hash).offset().top
              }, 1200, function(){
                window.location.hash = hash;
              });
            } 
      });

    $("#menucourses").click(function(event) {
        event.preventDefault();
        if (window.matchMedia("(max-width: 768px)").matches){
            $(location).attr('href', $(this).attr('href'));
            $("#menu-ul").hide();
            return true;
        }
        else{
            $("#menu-ul").toggle(); 
        }
       
        event.stopPropagation();
    });

    $('body').on('click',function(){
        var element = event.target;
        if($(element).closest(".call-container, #menu-ul ,#country-selectbox").length > 0)
        {
            return false;
        }
        else if($(element).hasClass('.call-container'))
        {
            return false;
        }
        else{
            $('.call-popup').removeClass('active');
            $(".country-list").removeClass('active');
        }
        $('#menu-ul').hide();
    });



    // (function($){
    //     $(document).ready(function(){
            $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
               
                $(this).parent().siblings().removeClass('open');
                $(this).parent().addClass('open');
            });
    //     });
    // })(jQuery);

    //top bar toggle 
    $(".toggle .menu").click(function() {
        $(this).parent().toggleClass('active');
    });
    //call tab js
    $(".call-link").click(function() {
        $(".call-popup").toggleClass('active');
    });

    $("#filter-btn").click(function() {
        $(".virtual-bar").toggleClass('active');
    });

    $(".country-select .country-select-open").click(function() {
        //     $(this).parent().toggleClass('active');
        $(".country-list").toggleClass('active');
    });

    $(".country-list .country").click(function() {

        var conntrycode = $(this).data("country-code");
        // alert(conntrycode); 
    });

    //tabs click
    tabjsInit();
    $(".tabjs-switch").on("click", tabjsSwitch);

    //call tabs click
    calltabjsInit();
    $(".calltabjs-switch").on("click", calltabjsSwitch);

    // modal box
    $.modal.defaults.closeClass = 'close-link';

    $("a[href='#modal1']").on('click', function() {
        $('.footer-modal').modal({
            blockerClass: "modal-blocker"
        });
        return false;
    });

    $('.modal').on($.modal.BEFORE_OPEN, function(event, modal) {

        $('.success').hide();
        $('#enquiryForm').show();
        $('#enquiryForm')[0].reset();


    });

        // Add smooth scrolling to all links
        //$("a").on('click', function(event) {
            // Make sure this.hash has a value before overriding default behavior
            //if (this.hash !== "") {
            // Prevent default anchor click behavior
            //event.preventDefault();
            // Store hash
            //var hash = this.hash;
            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            //$('html, body').animate({
              //  scrollTop: $(hash).offset().top
           // }, 800, function(){
                // Add hash (#) to URL when done scrolling (default click behavior)
             //   window.location.hash = hash;
           // });
          //  } // End if
        //});


        
    // carousel for large screen
    if ($(window).width() >= 1024) {
        $('.owl-carousel.Accredited-owl-carousel').owlCarousel({
            loop: true,
            slideSpeed: 500,
            autoplaySpeed: 1000,
            autoplayHoverPause: true,
            margin: 0,
            //autoWidth:true,
            nav: true,
            //navText : ["",""],
            autoPlayHoverPause: true,
            responsiveClass: true,
            dots: true,

            responsive: {
                0: {
                    items: 1,
                    autoplay: false,
                    nav: false
                },
                600: {
                    items: 4,
                    autoplay: false,
                    nav: false
                },
                1000: {
                    items: 4,
                    autoplay: true,
                    nav: false,
                }
            }
        });
    } else {

        $(".Accredited-logo.Accredited-owl-carousel").removeClass("owl-carousel");
    }


    $('.owl-carousel.Test-about-carousel').owlCarousel({
        loop: true,
        slideSpeed: 500,
        autoplaySpeed: 1000,
        autoplay: true,
        autoplayHoverPause: true,
        margin: 0,
        //autoWidth:true,
        nav: true,
        //navText : ["",""],
        autoplayHoverPause: true,
        responsiveClass: true,
        dots: true,

        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 1,

                nav: true
            },
            1000: {
                items: 1,

                nav: true,
            }
        }
    });

    //On scroll Footer Show hide
    $(document).scroll(function() {
        if($("#scrolllimit").length < 1)
        return false;
        var height = $(window).height(); //Getting Device height
        var deviceWidth = $(window).width();
        if (deviceWidth >= 1340) {
            return false;
        }
        var y = $(this).scrollTop() + height; //Adding device height to compare scroll bottom
        var div = $("#scrolllimit").offset().top; //Top of target Div
        if (y > div) {
            $(".fixed-enq").fadeIn();
        } else {
            $(".fixed-enq").fadeOut();
        }
    });

    //country selectbox 
    $("#country-selectbox li.country").on('click',function(){
        var countryCode = $(this).data('country-code');
    //    alert(window.location.href);
     
                window.location.href =  location.origin+'/'+countryCode+'/home';
               
           
    });

});

// document ready end here


//tabs Js Function
function tabjsInit() {
    // js tabs
    $(".tabjs").hide();
    $(".tabjs.tabjs-default").show();
}

function tabjsSwitch() {
    event.preventDefault();
    if ($(this).hasClass('active')) {
        return false;
    }
    var targetId = $(this).data("tabjs-target");
    var target = $("#" + targetId);

    target.show().siblings(".tabjs").hide();
    $(this).closest('.tabjs-container').find('.tabjs-switch').removeClass("active");
    $(this).addClass('active');
}

$(".accordion").click(function() {
    $(this).toggleClass("active");
    // $(this).next(".coursepanel").toggle();
    $(this).siblings(".coursepanel").find(".accordion").removeClass("active");
});


$("#srcollnext").click(function() {
    $("#step2").addClass("active");

});



//call-popup
function calltabjsInit()
{
// js tabs
$(".calltabjs").hide();
$(".calltabjs.calltabjs-default").show();
}

function calltabjsSwitch()
{
    event.preventDefault();
    if($(this).hasClass('active'))
    {
        return false;
    }
    var targetId = $(this).data("calltabjs-target");
    var target = $("#"+targetId);

    target.show().siblings(".calltabjs").hide();
    $(this).addClass('active').siblings('.calltabjs-switch').removeClass("active");
}

function checkConsent(button)
{
    var form = button.closest('form');
    var checkbox = $(form).find("input[name='contactConsent']");
    var error = checkbox.closest('form').find('.consent-error');
    if(checkbox.is(":checked"))
    {
        error.hide();
        return true;
    }
    error.show();
    event.preventDefault();
    return false;
}